import React from "react"
import BlackBox3D from "./BlackBox3D.js"
import polygon from "../images/polygon-logo.svg"

const buttonLink = {
    launchApp: { href: "#" },
    stakeBlack: { href: "https://app.blackboxfinancetech.com/staking" }
}

export default function MainContent1() {
    return (
        <div className="relative bg-gradient-to-tr from-black to-slate-900">
            <div className="">
                <section className="max-w-7xl mx-auto px-4 py-8 sm:flex justify-around sm:px-6 lg:px-12 lg:py-16">
                    <div className="flex flex-col justify-center lg:w-6/12 bg-slate-800/70 p-12 rounded-lg hover:shadow hover:shadow-xl hover:shadow-slate-200/50">
                        <div className="flex space-x-2">
                            <p className="text-slate-300 font-thin text-base  sm:text-lg">To make your protocols more sustainable.</p>
                        </div>
                        <h1 className="text-white text-4xl mb-4 md:text-6xl lg:text-7xl sm:mb-4">Deep and reliable <span className="text-transparent bg-clip-text bg-gradient-to-r from-sky-500 to-yellow-500">Liquidity</span> for DeFi protocols.</h1>
                        <p className="text-white text-base w-auto mb-2 sm:text-lg sm:mb-4">Single-sided liquidity pools with Impermanent Loss mitigation for end-users.</p>

                        <div className="flex justify-center flex-col space-y-2 sm:flex-row sm:justify-start sm:space-x-2 sm:space-y-0">
                            <a href={buttonLink.launchApp.href} key="Launch App" className="sm:w-1/3 text-center text-white rounded-lg h-12 pt-3 bg-sky-700 hover:bg-sky-400 font-medium">
                                In development
                            </a>
                        </div>
                    </div>
                    <div className="invisible h-0 md:grow md:visible md:h-96 self-center lg:h-[32rem] lg:grow">
                        <BlackBox3D />
                    </div>
                </section>
            </div>
        </div>
    )
}