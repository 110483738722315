import React from "react"
import SavingCost from "../images/saving-cost-pic.png"
import EarnYield from "../images/earn-yield-at-ease-pic.png"
import SustainableProtocol from "../images/sustainable-protocol-pic.png"


const buttonLink = {
    readMore: { href: "https://docs.blackboxfinancetech.com/how-to-participate/black-for-defi-builders/the-cost-of-liquidity" }
}

export default function MainContent4() {
    return (
        <div className="relative bg-morph-2-bg bg-no-repeat bg-cover">
            <section className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-12 lg:py-20 grid grid-cols-12">
                <h2 className="text-4xl font-semibold mb-8 col-start-4 col-end-10 text-center">Benefits</h2>
                <div className="h-auto md:grid md:gap-6 md:grid-cols-10 col-start-1 col-end-13 space-y-4 sm:space-y-0 sm:grid sm:grid-cols-10">
                    <div className="col-start-1 col-end-4 flex flex-col content-start space-y-2 p-12 rounded-2xl bg-zinc-50/50 hover:bg-zinc-50 outline outline-4 outline-slate-300 hover:outline-slate-600 ">
                        <h3 className="text-xl font-semibold">Saving cost</h3>
                        <p className="text-base">Builders can achieve an 800% cost saving to maintain the same amount of liquidity.
                            See more info <a href={buttonLink.readMore.href} className="font-medium text-sky-700">here</a>. </p>
                        <img src={SavingCost} alt="Impermanent Loss" className="w-2/3 self-center" />

                    </div>
                    <div className="col-start-4 col-end-8 flex flex-col content-start space-y-2 p-12 rounded-2xl bg-zinc-100/50 hover:bg-zinc-50 outline outline-4 outline-slate-300 hover:outline-slate-600">
                        <h3 className="text-xl font-semibold">Earning yield at ease</h3>
                        <p className="text-base">Depositing the assets to SSLPs will gain full exposure, IL mitigation, and rewards of BLACK.
                            Thus, end-users can invest the holding assets with a long-term plan and low monitoring.  </p>
                        <img src={EarnYield} alt="Hyperinflated tokenomics" className="w-2/3 self-center" />
                    </div>
                    <div className="col-start-8 col-end-11 flex flex-col content-start space-y-2 p-12 rounded-2xl bg-zinc-100/50 hover:bg-zinc-50 outline outline-4 outline-slate-300 hover:outline-slate-600">
                        <h3 className="text-xl font-semibold">Sustainable Protocol</h3>
                        <p className="text-base">Reducing protocol rewards emission, a protocol can re-design their tokenomic to be more sustainable, lifting the heavy burden from attracting early liquidity.</p>
                        <img src={SustainableProtocol} alt="Massive Cost" className="w-2/3 self-center" />
                    </div>
                </div>
            </section>
        </div>
    )
}