import './App.css';
import Navbar from "./components/Navbar"
import MainContent1 from './components/MainContent1';
import MainContent2 from './components/MainContent2';
import MainContent3 from './components/MainContent3';
import MainContent4 from './components/MainContent4';
import Footer from "./components/Footer"

function App() {
  return (
    <div className="App">
      <Navbar />
      <MainContent1 />
      <MainContent2 />
      <MainContent3 />
      <MainContent4 />
      <Footer />

    </div>
  );
}

export default App;
