import React from "react"
import ILPic from "../images/IL-pic.png"
import ILPicGrey from "../images/IL-pic-grey.png"
import HyperInflatePic from "../images/Hyperinflate-pic.png"
import MassiveCost from "../images/Massive-cost-pic.png"


const buttonLink = {
    readMore: { href: "https://docs.blackboxfinancetech.com/blackbox-guide/participate-blackbox" }
}

export default function MainContent2() {
    return (
        <div className="relative bg-morph-1-bg bg-no-repeat bg-cover">
            <section className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-12 lg:py-20 sm:grid sm:grid-cols-12">
                <h2 className="text-4xl font-semibold mb-8 col-start-4 col-end-10 text-center">What are the problems?</h2>
                <div className="h-auto md:grid md:gap-6 md:grid-cols-10 col-start-1 col-end-13 flex flex-col space-y-4 sm:space-y-0 sm:grid sm:grid-cols-10">
                    <div className="col-start-1 col-end-4 flex flex-col content-start space-y-2 p-12 rounded-2xl bg-zinc-50/50 outline outline-4 outline-slate-300 hover:bg-zinc-50 hover:outline-slate-600 ">
                        <h3 className="text-xl font-semibold">Impermanent Loss</h3>
                        <p className="text-base">Lack of incentive to provide liquidity, especially to early DeFi projects.
                            Since the price is highly volatile, Impermanent Loss plays a major role in net gain/loss. </p>
                        <img src={ILPic} alt="Impermanent Loss" className="w-2/3 self-center" />

                    </div>
                    <div className="col-start-4 col-end-8 flex flex-col content-start space-y-2 p-12 rounded-2xl bg-zinc-50/50 outline outline-4 outline-slate-300 hover:bg-zinc-50 hover:outline-slate-600">
                        <h3 className="text-xl font-semibold">Hyper-inflated tokenomics</h3>
                        <p className="text-base">For the purpose of incentivizing liquidity providers.
                            A protocol results in hyperinflation and an inevitable decline in the depth of liquidity once those rewards dry up. </p>
                        <img src={HyperInflatePic} alt="Hyperinflated tokenomics" className="w-2/3 self-center" />
                    </div>
                    <div className="col-start-8 col-end-11 flex flex-col content-start space-y-2 p-12 rounded-2xl bg-zinc-50/50 outline outline-4 outline-slate-300 hover:bg-zinc-50 hover:outline-slate-600">
                        <h3 className="text-xl font-semibold">Massive Cost</h3>
                        <p className="text-base">Due to traditional market design, pool 2,
                            Builders of new DeFi projects bear massive costs pursuing liquidity solutions.</p>
                        <img src={MassiveCost} alt="Massive Cost" className="w-2/3 self-center" />
                    </div>
                </div>
            </section>
        </div>
    )
}