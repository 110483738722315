import React from "react"
import BlackBoxOverview from "../images/Blackbox-Protocol-Overview-White-Crop.png"

const buttonLink = {
    stakeBlack: { href: "https://app.blackboxfinancetech.com/staking" },
    buyBlack: { href: "https://quickswap.exchange/#/analytics/pair/0xC77f6b3624f6f1A6157482AE74c94Ba2923ac96C" },
    docs: { href: "https://docs.blackboxfinancetech.com/" },
}

export default function MainContent3() {
    return (
        <div className="relative bg-gradient-to-tr from-black to-slate-800">
            <section className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-12 lg:py-20 flex flex-col">
                <h2 className="text-white text-4xl font-semibold text-left mb-4">What is Blackbox?</h2>
                <p className="text-white text-lg">Blackbox is a DeFi protocol where the end-user and builder need are met by providing
                    <span className="text-transparent bg-clip-text bg-gradient-to-r from-sky-400 to-yellow-400"> single-sided liquidity pools(SSLPs) </span>
                    to end-users and deep and reliable
                    <span className="text-transparent bg-clip-text bg-gradient-to-r from-sky-400 to-yellow-400"> liquidity </span>to builders. See more info
                    <a href={buttonLink.docs.href} className="text-sky-400 font-medium"> here</a></p>
                <div className="self-center">
                    <img src={BlackBoxOverview} alt="Blackbox Overview" className="h-auto sm:h-screen" />
                </div>
            </section>
        </div>
    )
}