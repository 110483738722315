import React from "react"
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import TwitterLogo from "../images/Twitter-logo-1.svg"
import DiscordLogo from "../images/Discord-logo-1.svg"
import TwitterLogoBlack from "../images/Twitter-logo-black.svg"
import DiscordLogoBlack from "../images/Discord-logo-black.svg"

const BUTTONLINK = {
    twitter: { href: "https://twitter.com/blackboxfintech" },
    discord: { href: "https://discord.gg/7VR3NsvKsg" },
}

export default function CommunityMenu() {
    return (
        <div className="h-full w-28 flex-col flex justify-center hover:text-sky-600 font-medium">
            <Menu as="div" className="relative inline-block text-left h-full">
                <div className="h-full">
                    <Menu.Button className="font-regular h-full w-28 text-center text-white hover:text-sky-600">
                        Community
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-1 ">
                            <Menu.Item>
                                {({ active }) => (
                                    <a href={BUTTONLINK.twitter.href}
                                        className={`${active ? 'bg-slate-700 text-white' : 'text-gray-900'
                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >
                                        {active ? (<img src={TwitterLogo} alt="Twitter-Logo" className="h-4 mr-1" />) : (
                                            <img src={TwitterLogoBlack} alt="Twitter-Logo" className="h-4 mr-1" />
                                        )}
                                        Twitter
                                    </a>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <a href={BUTTONLINK.discord.href}
                                        className={`${active ? 'bg-slate-700 text-white' : 'text-gray-900'
                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >
                                        {active ? (<img src={DiscordLogo} alt="Discord-Logo" className="h-4 mr-2" />) :
                                            (<img src={DiscordLogoBlack} alt="Discord-Logo" className="h-4 mr-2" />)}
                                        Discord
                                    </a>
                                )}
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}

/* return (
    <div className="fixed top-16 w-56 text-right">
      
    </div>
  )
}
 */