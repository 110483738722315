import React from "react"
import BlackboxLogo from "./BlackboxLogo"
import TwitterLogo from "../images/Twitter-logo-1.svg"
import GithubLogo from "../images/GitHub-Mark-Light-32px.png"
import DiscordLogo from "../images/Discord-logo-1.svg"

const BUTTONLINK = {
    twitter: { href: "https://twitter.com/blackboxfintech" },
    telegram: { href: "https://t.me/blackboxfinancetech" },
    discord: { href: "https://discord.gg/7VR3NsvKsg" },
    github: { href: "https://github.com/blackboxfinancetech" },
    blackBox: { href: "https://app.blackboxfinancetech.com/" },
    staking: { href: "https://app.blackboxfinancetech.com/staking" },
    documents: { href: "https://docs.blackboxfinancetech.com/" },
    faq: { href: "https://docs.blackboxfinancetech.com/introduction/faq" }
}

export default function Footer() {


    return (
        <div className="relative bg-black">
            <footer className="md:grid md:grid-cols-10 text-white max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-12 lg:py-12">
                <div className="col-start-1 col-end-3 mb-4 md:mb-0">
                    <BlackboxLogo />
                    <div className="ml-4 flex flex-col space-y-3 justify-between content-center mt-4 text-sky-300 font-medium">
                        <div className="flex space-x-2 content-center">
                            <a className="w-6" href={BUTTONLINK.twitter.href} key="twitter-logo"><img src={TwitterLogo} /></a>
                            <a href={BUTTONLINK.twitter.href} key="twitter">Twitter</a>
                        </div>
                        <div className="flex space-x-2 content-center ">
                            <a className="w-6" href={BUTTONLINK.github.href} key="telegram-logo"><img src={GithubLogo} /></a>
                            <a href={BUTTONLINK.github.href} key="telegram">Github</a>
                        </div>
                        <div className="flex space-x-2 content-center">
                            <a className="w-6" href={BUTTONLINK.discord.href} key="discord-logo"><img src={DiscordLogo} /></a>
                            <a href={BUTTONLINK.discord.href} key="discord">Discord</a>
                        </div>
                    </div>
                </div>
                <div className="col-start-4 col-end-6 flex flex-col mb-4 md:mb-0">
                    <h3 className="font-semibold text-lg mb-2">Products</h3>
                    <a href={BUTTONLINK.blackBox.href} key="Blackbox">Blackbox</a>
                </div>
                <div className="col-start-6 col-end-8 flex flex-col mb-4 md:mb-0">
                    <h3 className="font-semibold text-lg mb-2">Learn</h3>
                    <a href={BUTTONLINK.documents.href} key="Documents">Documents</a>
                    <a href={BUTTONLINK.faq.href} key="FAQ">FAQ</a>
                </div>
                <div className="col-start-8 col-end-10 flex flex-col">
                    <h3 className="font-semibold text-lg mb-2">Contact</h3>
                    <p><a href="mailto:hello@blackboxfinancetech.com">hello@blackboxfinancetech.com</a></p>
                </div>
            </footer>
        </div>
    )
}